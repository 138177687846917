import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import { useEffect } from "react";
import {
  FaArrowDown,
  FaArrowRight,
  FaCalendar,
  FaCircle,
  FaTruckLoading,
} from "react-icons/fa";
import { useQuery } from "react-query";
import LayoutNoNav from "../../components/layoutNoNav";
import Seo from "../../components/seo";

export default function Sertifikat(props) {
  const seo = {
    metaTitle: "Pencarian Data",
    metaDescription: "Pencarian Data",
    shareImage: null,
  };

  return (
    <LayoutNoNav as="article" {...props} locale="id">
      <Seo seo={seo} />
      <Component type={props?.params?.id || "sertifikat"} {...props} />
    </LayoutNoNav>
  );
}

const Component = ({ type, ...props }) => {
  const [currentDate, setCurrentDate] = React.useState("");
  const [currentDateCount, setCurrentDateCount] = React.useState({
    week: 1,
    month: "",
    year: 0,
  });

  const [currentDateCountNumber, setCurrentDateCountNumber] = React.useState({
    week: "",
    month: "",
    year: "",
  });

  const [jumlahSH, setJumlahSH] = React.useState("");
  const [jumlahProdukSH, setJumlahProdukSH] = React.useState("");
  const [thisWeek, setThisWeek] = React.useState("");
  const [thisMonth, setThisMonth] = React.useState("");
  const [thisYear, setThisYear] = React.useState("");

  const updateDate = () => {
    const now = new Date();
    const formattedDate =
      now.toLocaleDateString("id-ID", {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
      }) +
      ` ${now.toLocaleTimeString("id-ID", {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false, // Use 24-hour format
      })}`;
    setCurrentDate(formattedDate);
  };

  // Function to get the week number within the current month
  const getWeekOfMonth = (date) => {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const dayOfWeek = firstDayOfMonth.getDay(); // Day of the week (0 = Sunday, 6 = Saturday)
    const dayOfMonth = date.getDate(); // Current day in month
    return Math.ceil((dayOfMonth + dayOfWeek) / 7); // Calculate week number in the month
  };

  const updateDateCount = () => {
    const now = new Date();
    const weekNumber = getWeekOfMonth(now);
    const monthName = now.toLocaleString("id-ID", { month: "long" }); // "February"
    const year = now.getFullYear();

    setCurrentDateCount({ week: weekNumber, month: monthName, year });
  };

  const updateDateCountNumber = () => {
    const now = new Date();
    const weekNumber = getWeekOfMonth(now); // Assuming getWeekOfMonth is a defined function
    const monthNumber = now.getMonth() + 1; // Get the month as a number (1-12)
    const year = now.getFullYear();

    setCurrentDateCountNumber({ week: weekNumber, month: monthNumber, year });
  };

  // Function to format numbers with thousands separator
  const formatNumber = (num) => {
    return num !== null ? num.toLocaleString("id-ID") : "-";
  };
  // Fetch data for jumlah SH
  const {
    data: responseSH,
    refetch: refetchSH,
    isFetching: isFetchingSH,
    isError: isErrorSH,
  } = useQuery({
    queryKey: ["jumlah_sh"],
    queryFn: async () => {
      const url = "https://prod-api.halal.go.id/v1/informasi/jumlah_sh";
      const response = await fetch(url);
      if (!response.ok) throw new Error("Network response was not ok");
      return response.json();
    },
    enabled: false,
  });

  // Fetch data for jumlah produk SH jenis daftar
  const {
    data: responseProdukSH,
    refetch: refetchProdukSH,
    isFetching: isFetchingProdukSH,
    isError: isErrorProdukSH,
  } = useQuery({
    queryKey: ["jumlah_produk_sh_jenis_daftar"],
    queryFn: async () => {
      const url =
        "https://prod-api.halal.go.id/v1/informasi/jumlah_produk_sh_jenis_daftar";
      const response = await fetch(url);
      if (!response.ok) throw new Error("Network response was not ok");
      return response.json();
    },
    enabled: false,
  });

  // Fetch data for jumlah produk SH jenis daftar
  const {
    data: responseSHSummary,
    refetch: refetchSHSummary,
    isFetching: isFetchingSHSummary,
    isError: isErrorSHSummary,
  } = useQuery({
    queryKey: ["jumlah_produk_sh_summary"],
    queryFn: async () => {
      const url =
        "https://gateway.halal.go.id/v1/registration/summary/sertifikat?filter[StartTglTerbit]=2025-01-01&filter[EndTglTerbit]=2025-12-31";
      const response = await fetch(url);
      if (!response.ok) throw new Error("Network response was not ok");
      return response.json();
    },
    enabled: false,
  });

  // Auto refetch every 5 minutes
  useEffect(() => {
    // refetchSH();
    // refetchProdukSH();
    // refetchSHSummary();
    const interval = setInterval(() => {
      refetchSH();
      refetchProdukSH();
      refetchSHSummary();
    }, 60000); // 5 minutes

    return () => clearInterval(interval);
  }, []);

  // Auto refetch every 5 minutes
  useEffect(() => {
    refetchSH();
    refetchProdukSH();
    refetchSHSummary();
  }, []);

  const set = () => {
    if (responseSH?.data) {
      setJumlahSH(responseSH.data[0].jumlah);
    }
    if (responseProdukSH?.data) {
      setJumlahProdukSH(responseProdukSH.data[0].Total_Produk_SH);
    }

    if (responseSHSummary?.GET_SUMMARYCERTIFICATE?.data) {
      const data = responseSHSummary?.GET_SUMMARYCERTIFICATE?.data;
      let totalThisWeek = 0;
      let totalThisMonth = 0;
      let totalThisYear = 0;

      // Loop through the data to calculate totalThisWeek, totalThisMonth, and totalThisYear
      for (let i = 0; i < data.length; i++) {
        const item = data[i];

        if (
          item.YearNumber.toString() ===
            currentDateCountNumber.year.toString() &&
          item.MonthNumber.toString() ===
            currentDateCountNumber.month.toString() &&
          item.WeekNumber.toString() === currentDateCountNumber.week.toString()
        ) {
          totalThisWeek += item.TotalCount;
        }

        // Check for totalThisMonth
        if (
          item.YearNumber.toString() ===
            currentDateCountNumber.year.toString() &&
          item.MonthNumber.toString() ===
            currentDateCountNumber.month.toString() &&
          item.WeekNumber.toString() === "Total"
        ) {
          totalThisMonth = item.TotalCount;
        }

        // Check for totalThisYear
        if (
          item.YearNumber.toString() ===
            currentDateCountNumber.year.toString() &&
          item.MonthNumber.toString() === "Total" &&
          item.WeekNumber.toString() === "Total"
        ) {
          totalThisYear = item.TotalCount;
        }
      }

      setThisWeek(totalThisWeek);
      setThisMonth(totalThisMonth);
      setThisYear(totalThisYear);
    }
  };

  useEffect(() => {
    set();
  });

  useEffect(() => {
    const timer = setInterval(() => {
      updateDate();
      updateDateCount();
      updateDateCountNumber();
    }, 1000); // Update every 24 hours

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    updateDate(); // Set initial date
    updateDateCount();
    updateDateCountNumber();
  }, []);

  return (
    <React.Fragment>
      <div className="container mt-4 flex w-full flex-col items-center justify-center">
        <div className="mb-4 flex flex-row items-center text-center">
          <StaticImage
            src="../../assets/logo_primary_purple.png"
            height={80}
            layout="fixed"
            placeholder="blurred"
            alt="Logo BPJPH"
            className="pl-2 "
          />
          <div className="flex flex-col items-start justify-start">
            <p className="text-lg md:text-2xl">Badan Penyelenggara </p>
            <p className="text-lg md:text-2xl">Jaminan Produk Halal (BPJPH)</p>
          </div>
        </div>

        <div className="mb-2">
          <div>
            <p className="text-5xl font-bold text-purple">
              Data Penerbitan Sertifikat Halal
            </p>
          </div>
        </div>

        <div className="items-right mb-4 mb-5 flex w-full flex-row justify-center">
          <div className="flex flex-row items-center gap-2">
            <div className="items-center text-2xl  text-purple">
              <FaCalendar />
            </div>
            <p className="text-2xl  text-purple">{currentDate}</p>
          </div>
        </div>

        <div className="mb-4 mb-5 flex flex-col items-center gap-5 md:flex-row">
          <div className="border-1 flex flex-row items-center justify-between gap-5 rounded-2xl border border-gray-100 bg-white p-5 shadow-md">
            <div className="rounded-full bg-white">
              <StaticImage
                src="../../assets/document.png"
                height={80}
                layout="fixed"
                placeholder="blurred"
                alt="Logo BPJPH"
              />
            </div>
            <div className="relative mr-2 ml-2 flex flex-col justify-start">
              <p className="mt-2 text-lg text-purple md:text-2xl">
                Jumlah Sertifikat Halal
              </p>
              <p className="mb-2 text-5xl font-extrabold text-purple md:text-7xl">
                {formatNumber(jumlahSH)}
              </p>
              <p className="absolute bottom-0 flex flex-row items-center gap-2 text-xs text-purple">
                {isFetchingSH && (
                  <>
                    <FaTruckLoading /> <>Request new data</>
                  </>
                )}
              </p>
            </div>
          </div>

          <div className="border-1 flex flex-row items-center justify-between gap-5 rounded-2xl border border-gray-100 bg-white p-5 shadow-md">
            <div className="rounded-full bg-white">
              <StaticImage
                src="../../assets/product_purple.png"
                height={80}
                layout="fixed"
                placeholder="blurred"
                alt="Logo BPJPH"
              />
            </div>
            <div className="relative mr-2 ml-2 flex flex-col justify-start">
              <p className="mt-2 text-lg text-purple md:text-2xl">
                Jumlah Produk Bersertifikat
              </p>
              <p className="mb-2 text-5xl font-extrabold text-purple md:text-7xl">
                {formatNumber(jumlahProdukSH)}
              </p>
              <p className="absolute bottom-0 flex flex-row items-center gap-2 text-xs text-purple">
                {isFetchingSH && (
                  <>
                    <FaTruckLoading /> <>Request new data</>
                  </>
                )}
              </p>
            </div>
          </div>
        </div>

        <div className="items-right mb-4 mb-10 flex w-full flex-row justify-center">
          <div className="flex flex-row items-center gap-5 text-purple">
            <p className="text-2xl">
              Minggu ke{" "}
              <span className="font-bold">{currentDateCount.week}</span>
            </p>
            <FaCircle />
            <p className="text-2xl">
              Bulan <span className="font-bold">{currentDateCount.month}</span>
            </p>
            <FaCircle />
            <p className="text-2xl">
              Tahun <span className="font-bold">{currentDateCount.year}</span>
            </p>
          </div>
        </div>

        <p className="flex flex-row items-center gap-2 text-xs text-purple">
          {isFetchingSHSummary && (
            <>
              <FaTruckLoading /> <>Request new data</>
            </>
          )}
        </p>

        <div className="mb-5 flex flex-col items-center gap-5 md:flex-row">
          <div className="border-1 flex flex-row items-center justify-between gap-5 rounded-2xl border border-gray-100 bg-purple p-5 shadow-md">
            <div className="flex w-80 flex-col justify-start">
              <p className="text-xl text-white">Minggu ini</p>
              <p className="text-5xl font-extrabold text-white">
                {formatNumber(thisWeek)} SH
              </p>
            </div>
          </div>

          <div className="flex flex-row items-center justify-between gap-5 text-xl">
            <p>Dari</p>
            <div className="hidden md:flex">
              <FaArrowRight />
            </div>
            <div className="flex md:hidden">
              <FaArrowDown />
            </div>
          </div>

          <div className="border-1 flex flex-row items-center justify-between gap-5 rounded-2xl border border-gray-100 bg-purple p-5 shadow-md">
            <div className="flex w-80 flex-col justify-start">
              <p className="text-xl text-white">Target</p>
              <p className="text-5xl font-extrabold text-white">70.000 SH</p>
            </div>
          </div>
        </div>

        <div className="mb-5 flex flex-col items-center gap-5 md:flex-row">
          <div className="border-1 flex flex-row items-center justify-between gap-5 rounded-2xl border border-gray-100 bg-purple p-5 shadow-md">
            <div className="flex w-80 flex-col justify-start">
              <p className="text-xl text-white">Bulan ini</p>
              <p className="text-5xl font-extrabold text-white">
                {formatNumber(thisMonth)} SH
              </p>
            </div>
          </div>

          <div className="flex flex-row items-center justify-between gap-5 text-xl">
            Dari
            <FaArrowRight />
          </div>

          <div className="border-1 flex flex-row items-center justify-between gap-5 rounded-2xl border border-gray-100 bg-purple p-5 shadow-md">
            <div className="flex w-80 flex-col justify-start">
              <p className="text-xl text-white">Target</p>
              <p className="text-5xl font-extrabold text-white">290.000 SH</p>
            </div>
          </div>
        </div>

        <div className="mb-4 mb-5 flex flex-col items-center gap-5 md:flex-row">
          <div className="border-1 flex flex-row items-center justify-between gap-5 rounded-2xl border border-gray-100 bg-purple p-5 shadow-md">
            <div className="flex w-80 flex-col justify-start">
              <p className="text-xl text-white">Tahun ini</p>
              <p className="text-5xl font-extrabold text-white">
                {formatNumber(thisYear)} SH
              </p>
            </div>
          </div>

          <div className="flex flex-row items-center justify-between gap-5 text-xl">
            Dari
            <FaArrowRight />
          </div>

          <div className="border-1 flex flex-row items-center justify-between gap-5 rounded-2xl border border-gray-100 bg-purple p-5 shadow-md">
            <div className="flex w-80 flex-col justify-start">
              <p className="text-xl text-white">Target</p>
              <p className="text-5xl font-extrabold text-white">3.500.000 SH</p>
            </div>
          </div>
        </div>

        <div className="items-right  flex w-full flex-row justify-center">
          <div className="flex flex-row items-center gap-5 text-purple">
            <p className="text-2xl">Createdby PUSDATIN @2025</p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

